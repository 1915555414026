<template>
  <div>
    <b-overlay
      :show="load"
      variant="transparent"
    >
      <b-card>
        <b-button
          v-b-modal.importarRoteiro
          variant="primary"
        >
          Importar
        </b-button>
      </b-card>
    </b-overlay>
    <b-modal
      id="importarRoteiro"
      ref="importarRoteiro"
      title="Roteiro em lote"
      ok-title="Roteiros em lote"
      cancel-title="Cancelar"
      cancel-variant="danger"
      :ok-disabled="file === null || acao === '' "
      @ok="importarRoteirosEmLote"
    >
      <form
        ref="form"
      >
        <b-form-group
          label="Selecione o arquivo"
          label-for="name"
        >
          <b-form-file
            v-model="file"
            placeholder=""
            drop-placeholder="Arquivo"
          />
        </b-form-group>
        <label>Ação</label>
        <v-select
          v-model="acao"
          :options="optionsAcao"
        />
        <div v-if="acao && acao === 'ADICIONAR'">
          <label>Roteiro</label>
          <v-select
            v-model="roteiroID"
            :options="optionsRoteiro"
          />
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BFormFile,
  BFormGroup,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '../../../../axios-auth'

export default {
  components: {
    BButton,
    BModal,
    BFormFile,
    BFormGroup,
    BCard,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      file: null,
      roteiroID: null,
      optionsRoteiro: [],
      load: false,
      acao: '',
      optionsAcao: [
        'ADICIONAR',
        'REMOVER',
      ],
    }
  },
  async created() {
    await this.getRoteiros()
    this.$refs.importarRoteiro.show()
  },
  methods: {
    getRoteiros() {
      this.optionsRoteiro = []
      axios.get('api/v1/roteiro/')
        .then(res => {
          res.data.dados.map(item => {
            if (item.status) {
              this.optionsRoteiro.push({
                id: item.id,
                label: item.titulo,
              })
            }
          })
        })
    },
    importarRoteirosEmLote() {
      this.load = true
      const formData = new FormData()
      formData.append('planilha', this.file)
      formData.append('acao', this.acao)
      this.acao === 'ADICIONAR' ? formData.append('roteiro_id', this.roteiroID.id) : null
      axios.post('api/v1/roteiro/importar', formData)
        .then(() => {
          this.load = false
          this.file = null
          this.roteiroID = null
          this.acao = ''
          this.$swal({
            title: 'Importação realizada com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.load = false
          if (error.response) {
            this.$swal({
              title: 'Erro!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
  },
}
</script>
